export const stripHtmlAndSpecialChars = (str) => {
  const tmp = document.createElement("DIV");
  tmp.innerHTML = str;
  return tmp.textContent || tmp.innerText || "";
};

export const insertLineBreaks = (str, n) => {
  // Ensure n is a positive integer greater than 0
  const length = Math.max(1, n);
  const regex = new RegExp(`(.{1,${length}})(\\s|$)`, "g");
  const matches = str.match(regex) || [];
  return matches.map((line) => line.trim());
};

export const ProcessText = (str, n) => {
  // Strip HTML and special characters
  const cleanStr = stripHtmlAndSpecialChars(str);

  // Insert line breaks
  return insertLineBreaks(cleanStr, n);
};

export const ReplaceSepcialChars = (str) => {
  console.log("Original String:", str); // Debug input
  const cleanedStr = str
    .replace(/â€™/g, "’")
    .replace(/â€œ/g, "“")
    .replace(/â€/g, "”")
    .replace(/\uFFFD/g, "")
    // Handle all types of newline characters
    .replace(/(?:\r\n|\r|\n)/g, " ") 
    .trim();
  console.log("Cleaned String:", cleanedStr); // Debug output
  return cleanedStr;
};



