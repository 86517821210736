import React, { useEffect, useMemo } from "react";
import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
  Box,
  Typography,
  CircularProgress,
  Stack,
} from "@mui/material";
import Accordion from "../Accordion";
import { useSelector, useDispatch } from "react-redux";
import { getZonalOffices } from "../../redux/aboutUs/aboutUsAction";
import { ReplaceSepcialChars } from "../../util/escapeChar";

function ServicomContact({ content, loading }) {
  const { zonalOffices, isLoading } = useSelector((state) => state.about);
  const dispatch = useDispatch();

  const zonalOfficeKeys = useMemo(
    () => Object.keys(zonalOffices),
    [zonalOffices]
  );

  const headquatersTableHead = [
    "S/N",
    "NAMES",
    "DESIGNATION",
    "EMAIL",
    "PHONE NUMBER",
    "DEPARTMENT",
  ];
  const zonalTableHead = [
    "S/N",
    "ZONAL/STATE OFFICE",
    "NAMES",
    "ZONAL/STATE OFFICE ADDRESS",
    "PHONE ",
  ];
  const departmentalTableHead = [
    "S/N",
    "DEPARTMENT",
    "NAMES",
    "MOBILE NUMBER",
    "EMAIL",
  ];
  const liaisonTableHead = ["S/N", "NAMES", "PHONE NUMBER", "EMAIL", "ADDRESS"];

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#46C35F",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#FBFDFF",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  useEffect(() => {
    dispatch(getZonalOffices());
  }, [dispatch]);

  return (
    <Box sx={{ width: "100%" }}>
      <div className="about_management_img_wrapper">
        <h4>Contact</h4>
      </div>

      <Accordion
        heading={"SERVICOM Contacts at Headquaters"}
        content={
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 440, maxWidth: "100%" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {headquatersTableHead &&
                      headquatersTableHead?.map(
                        (td, key) =>
                          Boolean(td) && (
                            <StyledTableCell
                              key={key}
                              style={{
                                minWidth: 170,
                              }}
                            >
                              {td}
                            </StyledTableCell>
                          )
                      )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {content?.servicom_hq_table?.map((bio, key) => (
                    <StyledTableRow key={key}>
                      <StyledTableCell
                        style={{
                          minWidth: 170,
                        }}
                        component="th"
                        scope="row"
                      >
                        {key + 1}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          minWidth: 170,
                        }}
                      >
                        {bio?.name}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          minWidth: 170,
                        }}
                      >
                        {bio?.designation}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          minWidth: 170,
                        }}
                      >
                        {bio?.email}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          minWidth: 170,
                        }}
                      >
                        {bio?.phone}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          minWidth: 170,
                        }}
                      >
                        {bio?.department}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          // </Box>
        }
      />

      <Accordion
        heading={"Liaison Office FCT Abuja"}
        content={
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 440, maxWidth: "100%" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {liaisonTableHead &&
                      liaisonTableHead?.map(
                        (td, key) =>
                          Boolean(td) && (
                            <StyledTableCell
                              key={key}
                              style={{
                                minWidth: 170,
                              }}
                            >
                              {td}
                            </StyledTableCell>
                          )
                      )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {content?.servicom_liason_table?.map((bio, key) => (
                    <StyledTableRow key={key}>
                      <StyledTableCell
                        style={{
                          minWidth: 170,
                        }}
                        component="th"
                        scope="row"
                      >
                        {key + 1}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          minWidth: 170,
                        }}
                      >
                        {bio?.name}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          minWidth: 170,
                        }}
                      >
                        {bio?.phone}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          minWidth: 170,
                        }}
                      >
                        {bio?.email}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          minWidth: 170,
                        }}
                      >
                        {bio?.address}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          // </Box>
        }
      />
      <Accordion
        heading={"Departmental SERVICOM contact"}
        content={
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 440, maxWidth: "100%" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {departmentalTableHead &&
                      departmentalTableHead?.map(
                        (td, key) =>
                          Boolean(td) && (
                            <StyledTableCell
                              key={key}
                              style={{
                                minWidth: 170,
                              }}
                            >
                              {td}
                            </StyledTableCell>
                          )
                      )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {content?.departmental_contacts_table?.map((bio, key) => (
                    <StyledTableRow key={key}>
                      <StyledTableCell
                        style={{
                          minWidth: 170,
                        }}
                        component="th"
                        scope="row"
                      >
                        {key + 1}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          minWidth: 170,
                        }}
                      >
                        {ReplaceSepcialChars(bio?.department)}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          minWidth: 170,
                        }}
                      >
                        {ReplaceSepcialChars(bio?.name)}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          minWidth: 170,
                        }}
                      >
                        {bio?.phone}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          minWidth: 170,
                        }}
                      >
                        {bio?.email}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          // </Box>
        }
      />

      <Accordion
        heading={"ZONAL AND STATE COORDINATORS"}
        content={React.Children.toArray(
          isLoading ? (
            <Stack
              width={"100%"}
              direction="row"
              alignItems={"center"}
              justifyContent="center"
            >
              <CircularProgress sx={{ color: "#46C35F", width: "20px" }} />
            </Stack>
          ) : zonalOfficeKeys.length > 0 ? (
            zonalOfficeKeys.map((zonalKey, index) => {
              const zonalData = zonalOffices[zonalKey] || [];

              const formattedHeading = zonalKey
              .split("_")
              .map(word => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ");

              return (
                <Accordion
                  key={index}
                  heading={formattedHeading }
                  content={
                    <Paper sx={{ width: "100%", overflow: "hidden" }}>
                      <TableContainer sx={{ maxHeight: 440, maxWidth: "100%" }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              {zonalTableHead.map((header, headerIndex) => (
                                <StyledTableCell
                                  key={headerIndex}
                                  style={{ minWidth: 170 }}
                                >
                                  {header}
                                </StyledTableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {zonalData.map((data, rowIndex) => {
                              let content = {};
                              try {
                                const jsonData = data?.[zonalKey]?.replace(
                                  /\\/g,
                                  ""
                                );
                                content = jsonData
                                  ? JSON.parse(jsonData)
                                  : data;
                              } catch (error) {
                                console.error(
                                  "Error parsing JSON for zonal office:",
                                  error
                                );
                              }

                              return (
                                <StyledTableRow key={rowIndex}>
                                  <StyledTableCell
                                    style={{ minWidth: 170 }}
                                    component="th"
                                    scope="row"
                                  >
                                    {rowIndex + 1}
                                  </StyledTableCell>
                                  <StyledTableCell style={{ minWidth: 170 }}>
                                    {content?.zonaloffice}
                                  </StyledTableCell>
                                  <StyledTableCell style={{ minWidth: 170 }}>
                                    {content?.names}
                                  </StyledTableCell>
                                  <StyledTableCell style={{ minWidth: 170 }}>
                                    {content?.zonalofficeaddress}
                                  </StyledTableCell>
                                  <StyledTableCell style={{ minWidth: 170 }}>
                                    {content?.phone}
                                  </StyledTableCell>
                                </StyledTableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  }
                />
              );
            })
          ) : (
            <Typography>No data available for zonal offices.</Typography>
          )
        )}
      />

      <p
        style={{
          margin: "2rem 0rem",
        }}
      >
        If your complaint is unresolved or you are dissatisfied with the
        resolution use our online Complaint Form
        <a
          href="https://forms.office.com/r/PDgrBtFR5R"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          (https://forms.office.com/r/PDgrBtFR5R).
        </a>
        <p>"You can contact us Via our email servicom@nti.edu.ng.</p>
        {/* ; or download the form{" "}
        <a
          href="https://Forms\CustomerComplaintForm.docx"
          target="_blank"
          rel="noopener noreferrer"
        >
          (Forms\Customer Complaint Form.docx)
        </a>{" "}
        fill and forward by e-mail to: servicom@nti.edu.ng or drop at the
        SERVICOM Division, Quality Assurance Department, NTI Headquarters, KM 5
        Kaduna – Zaria Express Way, Rigachikun-Kaduna. */}
      </p>
    </Box>
  );
}

export default ServicomContact;
