import * as AboutUsTypes from "./aboutUsTypes";
import { httpRequest } from "../../https/https";
import { API_ROUTES } from "../../constant/config";

export const setIsLoading = (value) => ({
  type: AboutUsTypes.IS_LOADING,
  payload: value,
});

export const aboutUs = () => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await httpRequest({
      url: API_ROUTES.aboutUs.route,
      method: API_ROUTES.aboutUs.method,
    });

    // console.log(response);

    dispatch({
      type: AboutUsTypes?.GET_ABOUT_US,
      payload: response,
    });
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const getStructureContent = () => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await httpRequest({
      url: API_ROUTES.ourStructure.route,
      method: API_ROUTES.ourStructure.method,
    });

    dispatch({
      type: AboutUsTypes?.GET_STUCTURE_PAGE_CONTENT,
      payload: response?.our_structure,
    });
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const getFunctionContent = () => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await httpRequest({
      url: API_ROUTES.get_function.route,
      method: API_ROUTES.get_function.method,
    });

    dispatch({
      type: AboutUsTypes?.GET_FUNCTION_PAGE_CONTENT,
      payload: response?.our_function_page,
    });
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const getGovernanceContent = () => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await httpRequest({
      url: API_ROUTES.get_governance.route,
      method: API_ROUTES.get_governance.method,
    });

    dispatch({
      type: AboutUsTypes?.GET_GOVERNANCE_PAGE_CONTENT,
      payload: response,
    });
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const getAchievementContent = () => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await httpRequest({
      url: API_ROUTES.get_achievement.route,
      method: API_ROUTES.get_achievement.method,
    });

    dispatch({
      type: AboutUsTypes?.GET_ACHIEVEMNT_PAGE_CONTENT,
      payload: response,
    });
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const getCentersContent = () => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await httpRequest({
      url: API_ROUTES.get_centers.route,
      method: API_ROUTES.get_centers.method,
    });

    dispatch({
      type: AboutUsTypes?.GET_CENTERS_PAGE_CONTENT,
      payload: response,
    });
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const getManagementContent = () => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await httpRequest({
      url: API_ROUTES.get_management.route,
      method: API_ROUTES.get_management.method,
    });

    dispatch({
      type: AboutUsTypes?.GET_MANAGEMENT_PAGE_CONTENT,
      payload: response,
    });
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const getZonalOffices = () => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await httpRequest({
      url: API_ROUTES?.get_zonal_offices?.route,
      method: API_ROUTES?.get_zonal_offices?.method,
    });

    console.log(' zonal',response);

    dispatch({
      type: AboutUsTypes?.GET_ZONAL_OFFICES,
      payload: response,
    });
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const getNorthEastContent = () => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await httpRequest({
      url: API_ROUTES.get_north_central.route,
      method: API_ROUTES.get_north_cent.method,
    });

    dispatch({
      type: AboutUsTypes?.GET_NORTH_EAST_PAGE_CONTENT,
      payload: response,
    });
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const getNorthWestContent = () => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await httpRequest({
      url: API_ROUTES.get_north_west.route,
      method: API_ROUTES.get_north_west.method,
    });

    dispatch({
      type: AboutUsTypes?.GET_NORTH_WEST_PAGE_CONTENT,
      payload: response,
    });
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const getSouthEastContent = () => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await httpRequest({
      url: API_ROUTES.get_south_east.route,
      method: API_ROUTES.get_south_east.method,
    });

    dispatch({
      type: AboutUsTypes?.GET_SOUTH_EAST_PAGE_CONTENT,
      payload: response,
    });
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const getSouthSouthContent = () => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await httpRequest({
      url: API_ROUTES.get_south_south.route,
      method: API_ROUTES.get_south_south.method,
    });

    dispatch({
      type: AboutUsTypes?.GET_SOUTH_SOUTH_PAGE_CONTENT,
      payload: response,
    });
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const getSouthWestContent = () => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await httpRequest({
      url: API_ROUTES.get_south_west.route,
      method: API_ROUTES.get_south_west.method,
    });

    dispatch({
      type: AboutUsTypes?.GET_SOUTH_WEST_PAGE_CONTENT,
      payload: response,
    });
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const slider = () => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await httpRequest({
      url: API_ROUTES?.get_home_page_slider?.route,
      method: API_ROUTES?.get_home_page_slider?.method,
    });

    console.log(response);

    dispatch({
      type: AboutUsTypes?.SLIDER_CONTENT,
      payload: response,
    });
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setIsLoading(false));
  }
};
