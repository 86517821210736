import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
  Box,
  Typography,
  CircularProgress,
  Stack,
} from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { useMediaQuery } from "react-responsive";
import Accordion from "../Accordion";
import { useSelector, useDispatch } from "react-redux";
import { getZonalOffices } from "../../redux/aboutUs/aboutUsAction";

// interface ZonalOffice {
//   ZonalOffice: string;
//   names: string;
//   zonalOfficeAddress: string;
//   phone: string;
// }

function ZonalOffices() {
  const { zonalOffices, isLoading } = useSelector((state) => state.about);

  const zonalOfficeKeys = useMemo(
    () => Object.keys(zonalOffices),
    [zonalOffices]
  );

  const dispatch = useDispatch();
  const isTablet = useMediaQuery({ query: "(max-width: 900px)" });


  const tableHead = [
    "S/N",
    "ZONAL/STATE OFFICE",
    "NAMES",
    "ZONAL/STATE OFFICE ADDRESS",
    "PHONE ",
  ];

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#46C35F",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#FBFDFF",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  useEffect(() => {
    dispatch(getZonalOffices());
  }, [dispatch]);

  return (
    <Box sx={{ width: "100%" }}>
      <div className="about_management_img_wrapper">
        <h4>Zonal & State Offices</h4>
        {/* <img
          src={Pics}
          alt=""
          className="about_section_images"
          className="about-us-setions-image"
        /> */}
      </div>

      <p>FIELD OPERATIONS AND STUDENTS’ SERVICES DEPARTMENT.</p>
      <p>
        <b>
          NAMES, ADDRESSES AND PHONE NUMBERS OF ZONAL AND STATE COORDINATORS AS
          AT 26TH SEPTEMBER, 2020
        </b>
      </p>

      <div className="table-table-wrapper"></div>

      {isLoading ? (
        <Stack
          width={"100"}
          direction="row"
          alignItems={"center"}
          justifyContent="center"
        >
          <CircularProgress sx={{ color: "#46C35F", width: "20px" }} />
        </Stack>
      ) : (
        <>
          {zonalOfficeKeys?.length > 0 ? (
            zonalOfficeKeys?.map((key, index) => (
              <Accordion
                key={index}
                heading={key.replace(/_/g, " ")}
                content={
                  <Paper sx={{ width: "100%", overflow: "hidden" }}>
                    <TableContainer sx={{ maxHeight: 440, maxWidth: "100%" }}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            {tableHead &&
                              tableHead.map(
                                (td, index_a) =>
                                  Boolean(td) && (
                                    <StyledTableCell
                                      key={index_a}
                                      style={{
                                        minWidth: 170,
                                      }}
                                    >
                                      {td}
                                    </StyledTableCell>
                                  )
                              )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {zonalOffices[key]?.map((data, index_b) => {
                            const jsonData = data?.[key]?.replace(/\\/g, "");
                            const content = data?.[key]
                              ? JSON.parse(jsonData ?? "{}")
                              : data;
                            return (
                              <StyledTableRow key={index_b}>
                                <StyledTableCell
                                  style={{
                                    minWidth: 170,
                                  }}
                                  component="th"
                                  scope="row"
                                >
                                  {index_b + 1}
                                </StyledTableCell>
                                <StyledTableCell style={{ minWidth: 170 }} >
                                  {content?.zonaloffice}
                                </StyledTableCell>
                                <StyledTableCell style={{ minWidth: 170 }}>
                                  {content?.names}
                                </StyledTableCell>
                                <StyledTableCell style={{ minWidth: 170 }}>
                                  {content?.zonalofficeaddress}
                                </StyledTableCell>
                                <StyledTableCell style={{ minWidth: 170 }}>
                                  {content?.phone}
                                </StyledTableCell>
                              </StyledTableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                }
              />
            ))
          ) : (
            <p>No data available</p>
          )}
        </>
      )}
    </Box>
  );
}

export default ZonalOffices;
