import { Box } from "@mui/material";
import React from "react";
import { ReplaceSepcialChars } from "../../util/escapeChar";

function NtiServicom({ content, loading }) {
  console.log("Raw content:", content?.general_info_body || "");

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
      className="servicom_nti"
      dangerouslySetInnerHTML={{
        __html: ReplaceSepcialChars(content?.general_info_body || ""),
      }}
    />
  );
}

export default NtiServicom;
